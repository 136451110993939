import { useEffect, useState, useCallback, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditableTable from "./partials/TableManagers";
import SkeletonManagers from "./skeletons/SkeletonManagers";
import PrintModule from "./partials/PrintModule";

const Managers = (props) => {
  const {
    search,
    showSearchForm,
    setShowSearchForm,
    searchQuery,
    triggerSearchQuery,
    setTriggerSearchQuery,
    managerOverviewResults,
    getManagerOverViewResults,
    setPrintNode,
    printNode,
  } = useOutletContext();

  const [filteredManagerList, setFilteredManagerList] = useState(null);
  const { t } = useTranslation();

  const dataForGraph = useRef(null);

  useEffect(() => {
    if (!managerOverviewResults) {
      getManagerOverViewResults({
        ...search,
        ...(searchQuery && { manager_group_ids: searchQuery }),
      });
    }
  }, [getManagerOverViewResults, search, searchQuery, managerOverviewResults]);

  useEffect(() => {
    if ((triggerSearchQuery && search) || searchQuery) {
      getManagerOverViewResults({
        ...search,
        ...(searchQuery && { manager_group_ids: searchQuery }),
      });
      setTriggerSearchQuery(false);
    }
  }, [
    search,
    triggerSearchQuery,
    getManagerOverViewResults,
    setTriggerSearchQuery,
    searchQuery,
  ]);

  useEffect(() => {
    if (managerOverviewResults?.managerResults?.length > 0) {
      setFilteredManagerList(managerOverviewResults?.managerResults);
    } else {
      setFilteredManagerList([]);
    }
  }, [managerOverviewResults]);

  const handleSearch = useCallback(
    (searchQuery) => {
      let filteredList = [];
      if (searchQuery !== "") {
        const lowercaseSearchQuery = searchQuery.toLowerCase();
        filteredList = managerOverviewResults?.managerResults.filter(
          (manager) => {
            if (
              (typeof manager?.manager?.first_name === "string" &&
                manager?.manager?.first_name
                  .toLowerCase()
                  .includes(lowercaseSearchQuery)) ||
              (typeof manager?.manager?.last_name === "string" &&
                manager?.manager?.last_name
                  .toLowerCase()
                  .includes(lowercaseSearchQuery))
            ) {
              return true;
            }
            return false;
          }
        );
      } else {
        filteredList = managerOverviewResults?.managerResults;
      }

      setFilteredManagerList(filteredList);
    },
    [managerOverviewResults, setFilteredManagerList]
  );

  useEffect(() => {
    handleSearch(search.text);
  }, [search, managerOverviewResults, handleSearch]);

  const modifyDataForPrint = (html, class_remove) => {
    // Parse the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Identify the element you want to remove
    const elementToRemove = doc.querySelector("." + class_remove);

    // Remove the element if found
    if (elementToRemove) {
      elementToRemove.remove();
    }

    // Return the modified HTML content
    return doc.documentElement.innerHTML;
  };

  useEffect(() => {
    if (filteredManagerList?.length > 0) {
      setPrintNode(false);
    }

    return () => {
      setPrintNode(null);
    };
  }, [filteredManagerList, setPrintNode]);

  return (
    <>
      {!filteredManagerList ? (
        <SkeletonManagers />
      ) : filteredManagerList?.length > 0 ? (
        <>
          <div className="w-full" ref={dataForGraph}>
            <EditableTable
              dataList={filteredManagerList}
              showSearchForm={showSearchForm}
              setShowSearchForm={setShowSearchForm}
              categoriesData={managerOverviewResults?.categoriesData}
            />
          </div>
          {printNode && dataForGraph && (
            <PrintModule
              dataForGraph={
                <div
                  className="w-full p-4 bg-[#dedff1]"
                  dangerouslySetInnerHTML={{
                    __html: modifyDataForPrint(
                      dataForGraph.current.outerHTML,
                      "table-managers-info"
                    ),
                  }}
                />
              }
              setPrintNode={setPrintNode}
            />
          )}
        </>
      ) : filteredManagerList?.length === 0 ? (
        <div className="text-center">
          <h3>{t("general.no_results_found")}</h3>
        </div>
      ) : null}
    </>
  );
};

export default Managers;
